import axiosApi from 'axios';
import { store } from './../store';
import * as types from '@/store/mutation-types';
import router from './../router';
import { i18n } from './../main.js'
import Vue from 'vue';

const axiosAuthAPI = axiosApi.create({
  baseURL: process.env.VUE_APP_AUTH_API_HOST_URL   //baseurl for auth_service
});

const axiosDashboardAPI = axiosApi.create({
  baseURL: process.env.VUE_APP_DASHBOARD_API_HOST_URL   //baseurl for dashboard_service
});

axiosAuthAPI.interceptors.request.use(
  function (config) {
    config.headers.common['Accept'] = process.env.VUE_APP_ACCEPT_HEADER
    config.headers.post['Content-Type'] = 'application/json'

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axiosDashboardAPI.interceptors.request.use(
  function (config) {
    config.headers.common['Accept'] = process.env.VUE_APP_ACCEPT_HEADER
    config.headers.post['Content-Type'] = 'application/json'
    config.headers.common['Authorization'] = store.getters.getToken
    config.headers.common['Is-Live'] = !store.getters.getIsSandboxActive
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axiosDashboardAPI.interceptors.response.use(
  response => {
    return response
  },
  function (error) {
    if (
      router.currentRoute.path !== '/login' &&
      error.response.status === 401
    ) {
      Vue.toasted.error(i18n.t("COMMON.SESSIONEXPIRED"), {
        theme: 'bubble',
        duration: 6000
      })
      store.commit(types.setToken, null);
      router.replace('/login')
    }
    return Promise.reject(error)
  }
)

export const httpClient = {
  auth: axiosAuthAPI,
  dashboardService: axiosDashboardAPI,
}
